import styled from 'styled-components'
import React from 'react'
import { fluidRange } from 'polished'
import pixel from '../../content/assets/transparent-pixel.png'
import Img from 'gatsby-image'

/* Component */
const HomepageHeader = ({ headerData, headerVideo, headerVideoImage }) => {
  const { title, subTitle } = headerData
  return (
    <HeaderWrapper>
      <HeaderTextWrapper>
        <Preposition>THE</Preposition>
        <Title>{title.toUpperCase()}</Title>
        <SubTitle>{subTitle.toUpperCase()}</SubTitle>
      </HeaderTextWrapper>
      <VideoContainer>
        <BackgroundImage fluid={headerVideoImage} />
        <IntroVideo
          controls
          poster={pixel}
          disablePictureInPicture
          controlsList="nodownload"
        >
          <source src={headerVideo.url} type="video/mp4" />
        </IntroVideo>
      </VideoContainer>
    </HeaderWrapper>
  )
}

export default HomepageHeader

/* Styling */
const HeaderWrapper = styled.div`
  line-height: 1;
  letter-spacing: 2px;
  margin-bottom: 5%;
`
const HeaderTextWrapper = styled.h1``

const Preposition = styled.div`
  font-weight: 400;
  ${fluidRange(
    { prop: 'font-size', fromSize: `21px`, toSize: `64px` },
    '375px',
    '1200px'
  )};
`
const Title = styled.div`
  font-weight: 500;
  line-height: 1;
  display: inline-block;
  ${fluidRange(
    { prop: 'font-size', fromSize: `35px`, toSize: `127px` },
    '375px',
    '1200px'
  )};
  border-bottom: 2px solid;
`
const SubTitle = styled.div`
  ${fluidRange(
    { prop: 'font-size', fromSize: `18px`, toSize: `64px` },
    '375px',
    '1200px'
  )};
  font-weight: 500;
  text-align: center;
  color: ${(props) => props.theme.gray};
  padding-top: 15px;
`
const VideoContainer = styled.div`
  margin-top: 3%;
  position: relative;
`
const BackgroundImage = styled(Img)`
  position: absolute !important;
  width: 100%;
  border-radius: 10px;
`
const IntroVideo = styled.video`
  width: 100%;
  display: block;
  border-radius: 10px;
  :focus {
    outline: none;
  }
  position: relative;
  z-index: 10;
`
