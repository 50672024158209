import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Header from '../components/header'
import Layout from '../components/layout'
import BlogPostPreview from '../components/blog-post-preview'
import styled from 'styled-components'
import { useSiteMetadata } from '../hooks/useSiteMetadata'

/* Component */
const Home = ({ data, location }) => {
  const headerData = useSiteMetadata()
  const siteTitle = headerData.title
  const posts = data.allMdx.edges
  const headerVideoImage = data.videoImage.childImageSharp.fluid
  const headerVideo = data.video.edges[0].node.file

  return (
    <Layout location={location}>
      <SEO title={siteTitle} />
      <Header
        headerData={headerData}
        headerVideo={headerVideo}
        headerVideoImage={headerVideoImage}
      />
      <SectionTitle>Recent Posts</SectionTitle>
      <PostList>
        {posts.map(({ node }) => {
          return (
            <li key={node.fields.slug}>
              <BlogPostPreview post={node} />
            </li>
          )
        })}
      </PostList>
    </Layout>
  )
}

export default Home

/* Page Query for data */
export const pageQuery = graphql`
  query {
    videoImage: file(relativePath: { eq: "assets/home-image.JPG" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    video: allContentfulAsset(
      filter: { title: { eq: "Sufism Passion Project Video" } }
    ) {
      edges {
        node {
          file {
            contentType
            fileName
            url
          }
        }
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`

/* Styling */
const SectionTitle = styled.h2`
  border-bottom: 1px solid gray;
  font-variant: small-caps;
  font-weight: 600;
  font-size: ${(props) => props.theme.blogTitle};
  margin-right: 5%;
`
const PostList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`
