import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { fluidRange } from 'polished'

/* Component */
const BlogPostPreview = ({ post }) => {
  const {
    fields: { slug },
    frontmatter: { title, date, description, featuredImage },
  } = post
  return (
    <BlogPostWrapper>
      <Link to={`${slug}`}>
        <ImageContainer>
          <ImageText className="image-text">{title}</ImageText>
          <Image alt="" fluid={featuredImage.childImageSharp.fluid} />
        </ImageContainer>
      </Link>
      <TextContainer>
        <StyledLink to={`${slug}`}>
          <LinkContainer>{title}</LinkContainer>
          <DateText>{date}</DateText>
          <PostTextPreview>
            <p>{description}</p>
          </PostTextPreview>
        </StyledLink>
      </TextContainer>
    </BlogPostWrapper>
  )
}

export default BlogPostPreview

/* Styling */
const BlogPostWrapper = styled.div`
  margin: 40px 0px;
`
const Image = styled(Img)`
  max-height: inherit;
  transition: all 0.5s ease;
`
const ImageText = styled.div`
  ${fluidRange(
    { prop: 'font-size', fromSize: '32px', toSize: '120px' },
    '375px',
    '1200px'
  )}
  position: absolute;
  text-align: center;
  padding: 2% 5%;
  left: 10%;
  right: 10%;
  top: 50%;
  font-weight: 600;
  transform: translate(0%, -50%);
  z-index: 1;
  color: white;
  line-height: 1.2;
  font-variant: small-caps;
`
const ImageContainer = styled.div`
  max-height: 600px;
  margin: 20px 0px;
  overflow: hidden;
  border-radius: 15px;
  position: relative;
  ${ImageText} {
    opacity: 0;
    transition: all 0.5s ease;
  }

  @media (hover: hover) {
    &:hover ${ImageText} {
      opacity: 1;
      transition: all 0.5s ease;
    }

    &:hover ${Image} {
      transform: scale(1.1);
      transition: all 1s ease;
      filter: blur(2px) brightness(0.6);
    }
  }
`

const DateText = styled.div`
  padding-top: 5px;
  color: ${(props) => props.theme.gray};
`
const TextContainer = styled.div`
  margin-right: 5%;
`
const PostTextPreview = styled.div`
  font-size: ${(props) => props.theme.pageText};
`
const LinkContainer = styled.h2`
  border-bottom: 1px solid gray;
  margin: 0;
  font-variant: small-caps;
  font-weight: 600;
  font-size: ${(props) => props.theme.blogTitle};
`
const StyledLink = styled(Link)`
  text-decoration: none;
  transition: all 0.5s ease;
  @media (hover: hover) {
    :hover {
      color: ${(props) => props.theme.gray};
      transition: all 0.5s ease;
    }
  }
`
